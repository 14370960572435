import React from 'react';
import styles from './style.js';
import './style.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '../ui/Button';
import { Link } from 'react-router-dom';
import * as Actions from '../../redux/actions';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { getProducts } from '../../redux/selectors';
import MUTypography from '../ui/MUTypography';

class HowItWorks extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className = {classes.container}>
        <div className = {classes.imgBack}
          // style= {{ display: 'flex',
          //   justifyContent: 'flex-end', height: '100%', width: '70%' }}
            >
          <img src={require('../../images/how-it-works@2x.png')} alt="backImg" />

          <div className={classes.txtBackImage}>
            <MUTypography paragraph={true} className={classes.title}>
            How it works?
            </MUTypography>
            <MUTypography paragraph={true} className={classes.description}>
            Discover the FLYP Developer Portal documentation to see how to use more than 100 APIs we prepared for you under various product categories.
            </MUTypography>
            <Button
              text = 'Find Out More'
              classes={{ root: classes.button, text: classes.txtFindMore, label: classes.label }}
              component={Link}
              to='/docs'
            />
          </div>

        </div>
        <div className = {classes.imgFront}
        // style= {{height: '100%', width: '70%' }}
        >
          <img src={require('../../images/how-it-works-2.png')} 
            alt="frontImg" />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
  productsFromStore: getProducts(state)
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ), withStyles(styles))(HowItWorks);

