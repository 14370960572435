import * as React from 'react';
import { Link, Route } from 'react-router-dom';

class LiNavLink extends React.Component {
  render() {
    const {to, exact, strict, activeClassName, className,
      activeStyle, style, isActive: getIsActive, onClick, ...rest } = this.props;

    return (
      <Route
        path={typeof to === 'object' ? to.pathname : to}
        exact={exact}
        strict={strict}
        children={({ location, match }) => {
          const isActive = !!(getIsActive ? getIsActive(match, location) : match);

          return (
            <li className={isActive ? [activeClassName, className].join(' ') :
              className} style={isActive ? { ...style, ...activeStyle } : style} onClick={onClick}>
              <Link to={to} {...rest} />
            </li>
          );
        }}
      />
    );
  }
}

export default LiNavLink;
