import React from "react"
export class CustomBaseLayout extends React.Component {

  render() {
    const {specSelectors, getComponent} = this.props

    // let SvgAssets = getComponent("SvgAssets")
    // let InfoContainer = getComponent("InfoContainer", true)
    
    let Operations = getComponent("operations", true)
    let Models = getComponent("Models", true)
    let Row = getComponent("Row")
    let Col = getComponent("Col")
    
    // let Errors = getComponent("errors", true)
    // let ServersContainer = getComponent("ServersContainer", true)
    // const SchemesContainer = getComponent("SchemesContainer", true)
    // const FilterContainer = getComponent("FilterContainer", true)
    // let VersionPragmaFilter = getComponent("VersionPragmaFilter")
    // let isSwagger2 = specSelectors.isSwagger2()
    // let isOAS3 = specSelectors.isOAS3()

    const isSpecEmpty = !specSelectors.specStr()

    if(isSpecEmpty) {
      let loadingMessage
      let isLoading = specSelectors.loadingStatus() === "loading"
      if(isLoading) {
        loadingMessage = <div className="loading"></div>
      } else {
        loadingMessage = <h4>No API definition provided.</h4>
      }

      return <div className="swagger-ui">
        <div className="loading-container">
          {loadingMessage}
        </div>
      </div>
    }

    return (

      <div className='swagger-ui'>
              <Row>
                <Col mobile={12} desktop={12} >
                  <Operations/>
                </Col>
              </Row>
              <Row>
                <Col mobile={12} desktop={12} >
                  <Models/>
                </Col>
              </Row>
      </div>
    //   <div className='swagger-ui'>
    //       <SvgAssets />
    //       <VersionPragmaFilter isSwagger2={isSwagger2} isOAS3={isOAS3} alsoShow={<Errors/>}>
    //         <Errors/>
    //         <Row className="information-container">
    //           <Col mobile={12}>
    //             <InfoContainer/>
    //           </Col>
    //         </Row>

    //         <SchemesContainer/>

    //         <ServersContainer/>

    //         <FilterContainer/>

    //         <Row>
    //           <Col mobile={12} desktop={12} >
    //             <Operations/>
    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col mobile={12} desktop={12} >
    //             <Models/>
    //           </Col>
    //         </Row>
    //       </VersionPragmaFilter>
    //     </div>
      )
  }
}

// Create the plugin that provides our layout component
export const CustomBaseLayoutPlugin = () => {
    return {
      components: {
        CustomBaseLayout: CustomBaseLayout
      }
    }
  }