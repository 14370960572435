import React from 'react';
// import {MUTypography} from 'basic-components';
import MUTypography from '../../../../../ui/MUTypography';

const Info2 = (props) =>
  <div>
    <MUTypography variant='h4' className={props.classes.h4}>
Authorization
    </MUTypography>
    <br/>
    <MUTypography className={props.classes.paragraph} variant='display1'paragraph={true}>
In order to authorize FLYP APIs, we use OAuth2 protocol. APIs are handled as Public and Private depending on authorization types.
Public apis that doesn't contain customer data are used with Client Credentials Flow". In private APIs which require customer login, Authorization Code Flow is used for access.
    </MUTypography>
    <MUTypography variant='h5' className={props.classes.h5}>
    Client Credentials Flow
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Just take a token with client_id and client_secret credentials, then call FLYP Public APIs and analytic APIS.
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    sample request:
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    POST https://apitest.flyp.com.tr/auth-server/auth/oauth/token
    </MUTypography>

    <MUTypography variant='display1' className={props.classes.paragraph}>
      body parameters:
      <p>
        <ul>
          <li>grant_type: "client_credentials" (see also: OAuth 2.0 spec)</li>
          <li>scope: Authorized scope of Access Token  (“public” or “analytics”) </li>
        </ul>
      </p>
      header parameters:
      <p>
        <ul>
          <li>Authorization: Required. in format of "Basic {'<'}basic encoded client id secret{'>'}". (see Basic Authentication https://tools.ietf.org/html/rfc7617)</li>
          <li>Content-Type: "application/x-www-form-urlencoded"</li>
        </ul>
      </p>
    </MUTypography>

    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    response:
      <p>
        <ul>
          <li>access_token: An authorized token to call FLYP APIs</li>
          <li>token_type: always “Bearer”</li>
          <li>expires_in: Expiration time of access_token (seconds)</li>
          <li>scope: “public” or “analytics”</li>
        </ul>
      </p>
    on error case:
      <p>
        <ul>
          <li>status: http status code</li>
          <li>error: Error code</li>
          <li>message: Error description</li>
        </ul>
      </p>
    </MUTypography>

    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
      On a successful response from Authorization Server, you'll be provided an Access Token.
    </MUTypography>
  </div>;

export default Info2;

