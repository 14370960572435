const styles = theme => ({
  btnViewAll: {
    width: '136px',
    height: '48px',
    borderRadius: '32px',
    border: 'solid 2px #e61837',
    backgroundColor: '#fff',
    marginTop: '30px'
  },
  txtViewAll: {
    fontFamily: 'Gilroy',
    color: '#e61837',
    textTransform: 'capitalize'
  },
  label: {
    fontSize: '14px'
  }
});

export default styles;

