import React, { Component } from 'react';
import LiNavLink from '../LiNavLink';
import Copyrights from '../Copyrights';
import './style.css';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import * as apiDashboardService from '../../services/apidashboardservice';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import {getProducts as getProductsSelect} from '../../redux/selectors';
import {docsInfoDynamicContentScreen} from '../../redux/constants/docsInfoContentTypes';
import { history } from '../../App';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';
import PrivacyPolicyModal from '../ui/PrivacyPolicyModal/PrivacyPolicyModal'; 
import Modal from 'react-modal';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isPrivacyPolicyModalOpen: false,
      products: [],
    };
  }

  openPrivacyPolicyModal = () => {
    this.setState({ isPrivacyPolicyModalOpen: true });
  };

  closePrivacyPolicyModal = () => {
    this.setState({ isPrivacyPolicyModalOpen: false });
  };

  componentWillMount() {
    const productList = this.props.selectProducts;

    if (!_.isEmpty(productList)) {
      this.setState({ products: productList});
    } else {
      this.getProducts();
    }
  }

  onClick = (e, id) =>{
    e.preventDefault();
    this.props.actions.switchDocsMainContentToInfo();
    this.props.actions.setDocsApiInfo(id, docsInfoDynamicContentScreen, false);
    history.push({
      pathname: '/docs',
      state: { isLinked: true}
    });
    window.scrollTo(0, 0);

  }

  onDocumentationClick = (e, info) =>{
    e.preventDefault();
    this.props.actions.switchDocsMainContentToInfo();
    this.props.actions.setDocsApiInfo(info, info, false);
    history.push({
      pathname: '/docs',
      state: { isLinked: true}
    });
    window.scrollTo(0, 0);

  }

  getProducts() {
    apiDashboardService.getProducts()
      .then((responseJson) => {
        if (responseJson.data) {
          this.setState({ products: responseJson.data});
          this.props.actions.addProducts(responseJson.data);
        }
      })
      .catch((exception) => {
        console.log('get products error');
        // TODO: Show Generic Error to user - System error occured, please try again
        console.log('NewApplication - refreshProducts => ' + JSON.stringify(exception));
      });
  }

  render() {
    const { products } = this.state;
    const { classes } = this.props;
    const { isPrivacyPolicyModalOpen } = this.state;  
    return (
      <footer id="footer" className= {classNames('dark', classes.footer)}>

        <div className="container">
          {/* Footer Widgets */ }
          <div className="footer-widgets-wrap clearfix">
            <div className="col_full">
              <div className={classNames('widget clearfix')}>
                <div className={classNames('row', classes.menu)}>

                  <div className="col-lg-4 col-sm-12 col-md-6 bottommargin-sm widget_links">

                    <Link to='/'>
                      <img src={require('../../images/flyp_logo_colored.png')} alt="Canvas Logo" />
                    </Link>
                  </div>

                  <div className="col-lg-2 col-6 bottommargin-sm widget_links">
                    <h4>Products</h4>
                    <ul>
                      {products.map((item, key) =>
                        <LiNavLink key={key} exact={true} to='/'
                          onClick={(e) => this.onClick(e, item.id)}>{item.name}</LiNavLink>)}
                    </ul>
                  </div>

                  <div className="col-lg-2 col-6 bottommargin-sm widget_links">
                    <h4>Documentation</h4>
                    <ul>
                     <LiNavLink exact={true} to='/' onClick={(e) => this.onDocumentationClick(e, 'Info1')}>Getting Started</LiNavLink>
                     <LiNavLink exact={true} to='/' onClick={(e) => this.onDocumentationClick(e, 'Info2')}>Authorization</LiNavLink>
                     <LiNavLink exact={true} to='/' onClick={(e) => this.onDocumentationClick(e, 'Info3')}>API Usage</LiNavLink>
                     <LiNavLink exact={true} to="/PrivacyPolicy">Aydınlatma Metni</LiNavLink>
                    </ul>
                  </div>
                  <PrivacyPolicyModal
                   isOpen={isPrivacyPolicyModalOpen}
                   onRequestClose={this.closePrivacyPolicyModal}
                 />
                  <div className="col-lg-2 col-6 bottommargin-sm widget_links">
                    <h4>Support</h4>
                    <ul>
                      <LiNavLink exact={true} to="/faq">FAQ</LiNavLink>
                      <LiNavLink exact={true} to="/contact">Contact Us</LiNavLink>
                      <LiNavLink exact={true} to="/announcement">Announcements</LiNavLink>
                    </ul>
                  </div>

                  {/* <div className="col-lg-2 col-6 bottommargin-sm widget_links">
                    <div className={classes.link}>
                      <a href="https://www.albaraka.com.tr/" target="_blank" style ={{ marginBottom: '50%'}}><img src= {require('../../images/facebook.png')} ></img></a>
                      <a href="https://www.albaraka.com.tr/" target="_blank" style ={{ marginBottom: '50%'}}><img src= {require('../../images/twitter.png')} ></img></a>
                      <a href="https://www.albaraka.com.tr/" target="_blank" style ={{ marginBottom: '50%'}}><img src= {require('../../images/pinterest.png')} ></img></a>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div> { /* .footer-widgets-wrap end*/ }
        </div>
        {/* Footer Widgets */ }

        <Copyrights/>
      </footer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
  products: state.products,
  selectProducts: getProductsSelect(state)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Footer);
