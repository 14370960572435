import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import styles from './style.js';
import './style.css';
import Button from '../ui/Button';
import { Link } from 'react-router-dom';
import MUTypography from '../ui/MUTypography';
import BasicPageComponent from '../BasicPageComponent';
import FeaturedAPIs from '../FeaturedAPIs';
import HowItWorks from '../HowItWorks';
import Header from '../Header';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

class Home extends Component {

  render() {
    const {classes} = this.props;

    return (
      <div >

        <section id="slider" className="slider-element force-full-screen full-screen">
          <div className="force-full-screen full-screen dark bg">

            <div>
              <Header srcImage = {require('../../images/albaraka_api_white.png')} homepage= {true}/>
            </div>

            <div className = {classes.intro}>
              <MUTypography paragraph={true} className={classes.typography}>
              Let's build together the digital products of the future!
              </MUTypography>
            </div>

            <div>
              <Button
                variant='text'
                classes={{ root: classes.btnRegister, text: classes.txtRegister, label: classes.lblRegister }}
                text="REGISTER"
                component={Link}
                to="/register"
              />
            </div>

          </div>

          <div style = {{
            position: 'relative',
            zIndex: '4',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#F0F2F1',
            marginBottom: '-5%'}}>
            <a className ={classes.section4} href='#feature'>
              <img src = {require('../../images/scrollButton.png')} />
            </a>
          </div>

          <div id='feature' className="force-full-screen full-screen dark"
          >
            <BasicPageComponent
              title='Featured API’s'
              description='Discover the world of FLYP that has wide range of APIs ranging from accounts to payments, from investment products to credit cards, from KYC to OCR!'
              children = { <FeaturedAPIs />}
            />
          </div>
          <div>
            <HowItWorks />
          </div>
          {/* <div className = "footerImage">
            <div style = {{paddingTop: '250px'}}>
              <div>
                <MUTypography className={classes.footerImageText}>
          FLYP BANKING GROUP
                </MUTypography>
                <MUTypography className={classes.footerImageText}>
            GLOBAL NETWORK
                </MUTypography>
              </div>
              <div style = {{paddingTop: '40px'}}>
                <MUTypography className={classes.countryList}>
            JORDAN   INDONESIA   PAKISTAN   BAHRAIN   TURKEY   TUNUSIA   LIBYA   SAUDI ARABIA
                </MUTypography>
                <MUTypography className={classes.countryList}>
            ALGERIA   SOUTH AFRICA   SUDAN   SYRIA   LEBANON   EGYPT   IRAQ   MOROCCO
                </MUTypography>
              </div>
            </div>
          </div> */}
        </section>
      </div>
    );
  }
}

Home.propTypes = {
  login: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  login: state.login
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Home);
