import { call } from '../utils/serviceUtils';
import {
  APIDASHBOARDSERVICE_PRODUCTS,
  APIDASHBOARDSERVICE_APPLICATIONS,
  APIDASHBOARDSERVICE_PRODUCT_SERVICES,
  APIDASHBOARDSERVICE_PRODUCT_SERVICES_BY_ID,
  APIDASHBOARDSERVICE_SAVE_APPLICATION,
  APIDASHBOARDSERVICE_RENEW_TOKENS,
  APIDASHBOARDSERVICE_DELETE_APPLICATION,
  APIDASHBOARDSERVICE_GENERATE_REPORTS,
  APIDASHBOARDSERVICE_ANNOUNCEMENTS
} from '../config/paths';

/**
 * Mocking client-server processing
 */
import _products from './test/products';

export function getProducts(key) {
  return call.postBeforeLogin(APIDASHBOARDSERVICE_PRODUCTS,
    {

    });
}

export function getApplications() {
  return call.post(APIDASHBOARDSERVICE_APPLICATIONS,
    {

    }
  );
}

export function getProductServices() {
  return call.postBeforeLogin(APIDASHBOARDSERVICE_PRODUCT_SERVICES,
    {

    });
}

export function getProductServicesById(productId) {
  return call.post(APIDASHBOARDSERVICE_PRODUCT_SERVICES_BY_ID,
    {
      'productId': productId
    });
}

export const api = {
  getProducts() {
    return new Promise(resolve => {
      setTimeout(() => resolve(_products), 500);
    });
  }
};

export function saveApplication(id, name, description, icon,
  productList,
  oauthRedirectUri, publicKey, clientId, clientSecret, ipList) {
  return call.post(APIDASHBOARDSERVICE_SAVE_APPLICATION,
    {
      'id': id,
      'name': name,
      'description': description,
      'icon': icon,
      'productList': productList,
      'oauthRedirectUri': oauthRedirectUri,
      'publicKey': publicKey,
      'clientId': clientId,
      'clientSecret': clientSecret,
      'ipList': ipList
    });
}

export function renewTokens(id, clientId, clientSecret) {
  return call.post(APIDASHBOARDSERVICE_RENEW_TOKENS,
    {
      'id': id,
      'clientId': clientId,
      'clientSecret': clientSecret
    });
}

export function deleteApplication(id) {
  return call.post(APIDASHBOARDSERVICE_DELETE_APPLICATION,
    {
      'id': id
    });
}

export function generateReports(applciationId, serviceEndpoint, dateRangeId, requestDate) {
  return call.post(APIDASHBOARDSERVICE_GENERATE_REPORTS,
    {
      'applicationId': applciationId,
      'endpointAddress': serviceEndpoint,
      'dateRangeId': dateRangeId,
      'requestDate': requestDate
    });
}

export function getAnnouncements() {
  return call.postBeforeLogin(APIDASHBOARDSERVICE_ANNOUNCEMENTS,
    {

    });
}
