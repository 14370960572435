import React, { Component } from 'react';
import ScreenContainer from '../ui/ScreenContainer';
import './style.css';
import ControlledExpansionPanels from '../ui/ControlledExpansionPanels';
import Header from '../Header';
import BasicPageComponent from '../BasicPageComponent';
import Button from '../ui/Button';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';

const generalQuestions = [
  { id: 'p1',
    title: 'What is the FLYP?',
    content: 'FLYP offers licensed banking products and functions for start-ups, holdings, retail chains, SMEs, ERP chains, e-commerce and B2B trading platforms, ' +
    'e-money and payment institutions that want to be a part of the fintech transformation.'
  }
];

const openBankingQuestions = [
  { id: 'p2',
    title: 'What is Open Banking?',
    content: 'Open Banking is a definiton of ecosystem that APIs will allow banks,' +
     'fintechs and other third party providers to increase collaboration and ' +
     'Cooperation to be able to design easy-to-use experiences and innovative products for Customers.'
  }
];

const loginRegisterQuestions = [
  { id: 'p3',
    title: 'How do I register?',
    content: 'Click on the Register link at the top right of the page and follow the instructions. After filling the registration form and submitting,' +
     'an activation link will be sent to your e-mail inbox. After clicking the link you will be directed to confirmation page.'
  },
  { id: 'p4',
    title: 'How Do I login?',
    content: 'Click on the Log in link at the top right of the page. After typing your e-mail and password, you will be directed to “My Applications” page.'
  },

  { id: 'p5',
    title: 'What are the Password Requirements?',
    content: 'Your password should consist of at least 8 characters and at least one letter and one number.'
  },

  { id: 'p6',
    title: 'I forgot my password – how do I reset it?',
    content: 'On the Log in screen click the ‘Forgot your password?’ button and type your e-mail. A link will be sent to your inbox to renew your password.'
  }
];

const applicationQuestions = [
  { id: 'p7',
    title: 'What is an Application ?',
    content: 'An application is for a user to manage APIs credentials. ' +
    'It is identified by a unique app_id and an app_name given by the user and an app_secret generated by the system.'
  },
  { id: 'p8',
    title: 'How to create an application?',
    content: 'On the menü click “My Applications” page and follow the instructions'
  },
  { id: 'p9',
    title: 'How many applications can I create?',
    content: 'There is no limit for the number of applications you create.'
  },
  { id: 'p10',
    title: 'Can I use the same name for two different apps?',
    content: 'No, You can’t. Using the same name for different applications will make harder the categorization and management process.'
  },
  { id: 'p11',
    title: 'Can I create apps in more than one country?',
    content: 'Yes, you can create apps in any available country.'
  }
];

const supportQuestions = [
  { id: 'p12',
    title: 'How I send questions and requests related to APIs?',
    content: 'After browsing API Documentation if you couldn’t an answer' +
     ' please click “Contact Us” and follow the instruction to send your questions and requests.'
  },
  { id: 'p13',
    title: 'How to Go Live?',
    content: 'After you complete the development and tests of your applications, send your Production request via Contact Us page.'
  }
];

class Faq extends Component {

  constructor(props) {
    super(props);
    this.state = {
      limitedApplicationQuestions: [],
      limitedLoginRegisterQuestions: []
    };

  }
  componentWillMount() {
    // TODO: get faqs from service
    window.scrollTo(0, 0);
    this.setState({
      limitedApplicationQuestions: applicationQuestions.slice(0, 3),
      limitedLoginRegisterQuestions: loginRegisterQuestions.slice(0, 3)
    });
  }

  onClick = (e, name, value) => {
    e.preventDefault();
    this.setState({ [name]: [...value] });
  };

  render() {
    const { classes } = this.props;
    const { limitedApplicationQuestions, limitedLoginRegisterQuestions} = this.state;

    return (
      <div style = {{display: 'flex',
        flexDirection: 'column'}}>
        <Header />
        <BasicPageComponent
          title='Support'
          description='We prepared a page of frequently asked questions to answer possible questions in your mind.'
          children = {
            <ScreenContainer>
              <div style={{display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', padding: '30px 0', fontFamily: 'Gilroy', paddingBottom: '15%'}}>
                <div style={{padding: 10, width: '33%', minWidth: 300}}>
                  <h4>General</h4>
                  <ControlledExpansionPanels questions={generalQuestions}/>

                </div>
                <div style={{padding: 10, width: '33%', minWidth: 300}}>
                  <h4>Open Banking</h4>
                  <ControlledExpansionPanels questions={openBankingQuestions}/>
                </div>
                <div style={{padding: 10, width: '33%', minWidth: 300}}>
                  <h4>Support</h4>
                  <ControlledExpansionPanels questions={supportQuestions}/>
                </div>
                <div style={{padding: 10, width: '33%', minWidth: 300}}>
                  <h4>Login and Register</h4>
                  <ControlledExpansionPanels questions={limitedLoginRegisterQuestions}/>
                  <Button
                    classes={{ root: classes.btnViewAll, text: classes.txtViewAll, label: classes.label }}
                    text='View All'
                    onClick ={(e) => this.onClick(e, 'limitedLoginRegisterQuestions', loginRegisterQuestions)}
                  />

                </div>
                <div style={{padding: 10, width: '33%', minWidth: 300}}>
                  <h4>Applications</h4>
                  <ControlledExpansionPanels questions={limitedApplicationQuestions}/>
                  <Button
                    classes={{ root: classes.btnViewAll, text: classes.txtViewAll, label: classes.label }}
                    text='View All'
                    onClick ={(e) => this.onClick(e, 'limitedApplicationQuestions', applicationQuestions)}
                  />
                </div>

              </div>
            </ScreenContainer>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(Faq);
