import moment from 'moment';
import { store } from '../index.js';
import { AUTHSERVER_TOKEN } from '../config/paths';
import { toFormBody, checkStatus, parseJSON } from '../utils/fetchUtils';
import { tokenRefreshSuccess } from '../redux/actions';

const sem = require('semaphore')(1);
let isRefreshTokenValid = true;
let refreshTokenInProgress = false;

/**
 * Check if OAuth2 Token has passed its expiry date (is within 1 min)
 * @param  {object} response   A response from a network request
 * @return {boolean} Returns true if the token has expired
 */
export function isOAuth2TokenExpired(token, gap) {
  if (token && token.expireDate) {
    const expireDate = moment(token.expireDate);
    const now = moment();
    const diff = expireDate.diff(now);
    const preGap = gap ? (gap * 1000) : 60000; // 1 min

    if (diff > preGap) {
      return false;
    }
  }
  return true;
}

/**
 * Access Redux Store for OAuth2 Token and return it
 *
 * @return {object|undefined} Returns either the token or undefined if there is no token in Store
 */
export function getOAuth2Token() {
  const state = store.getState();

  if (state && state.login) {
    const login = state.login.toJS();

    if (login && login.token !== undefined) { return login.token; }
  }

  return undefined;
}

/**
 * refresh token if expired
 */
export function refreshToken(token) {
  if (!token) {
    token = getOAuth2Token();
  }
  refreshTokenInProgress = true;

  return fetch(AUTHSERVER_TOKEN, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic aW5zaGEtYXBpLXBvcnRhbDp0aGlzaXNzZWNyZXQ='
    },
    body: toFormBody({
      scope: 'portal',
      grant_type: 'refresh_token', // eslint-disable-line camelcase
      refresh_token: token.refresh_token // eslint-disable-line camelcase
    })
  })
    .then(res => { refreshTokenInProgress = false; return res;})
    .then(checkStatus)
    .then(response => { return response.json(); })
    .then(token => {
      const expireDate = moment().add(token.expires_in, 'seconds');

      token.expireDate = expireDate;

      store.dispatch(tokenRefreshSuccess(token));
      console.log(token);
    })
  ;
}

/**
 * Inject  OAuth2 Token to Fetch Options if it exists and is NOT expired
 * @param  {object} options   An options bject that can be used with fetch
 * @return {object} Return the same options object with Access token added
 */

export function injectRequiredHeaders(options, isRaw, beforeLogin) {
  const oAuth2Token = getOAuth2Token();
  const isTokenExpired = isOAuth2TokenExpired(oAuth2Token);
  // let readAccessToken = '';

  // if (beforeLogin) {
  //   readAccessToken = getOAuth2TokenBeforeLogin();
  // }

  if (options) {
    if (!options.headers) {
      options.headers = {};
    }
    // if (beforeLogin) {
    //   options.headers.Authorization = readAccessToken;
    // }
    if (!isTokenExpired && !beforeLogin) {
      options.headers.Authorization = oAuth2Token.token_type + ' ' + oAuth2Token.access_token;
    }
    if (!isRaw && !options.headers.Accept) {
      options.headers.Accept = 'application/json';
    }
    if (!isRaw && !options.headers['Content-Type']) {
      options.headers['Content-Type'] = 'application/json';
    }
    if (options.body && (typeof options.body !== 'string')) {
      options.body = JSON.stringify(options.body);
    }
    const date = new Date();

    options.headers['Client-Date'] = date.toString();
    options.headers['Client-Milliseconds'] = date.getTime().toString();
  }
  return options;
}

export function decodeJWTToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const tokenInfoString = atob(base64);

  return JSON.parse(tokenInfoString);
}

export function hasValidToken() {
  const token = getOAuth2Token();

  if (!token || isOAuth2TokenExpired(token)) return false;
  return true;
}

export function checkStatusAndAccess(response, originalRequest, isRaw) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401 || response.status === 403) {
    const result = fetchWithAccessToken(originalRequest, isRaw, true, true);

    if (response !== result) { return result; }
  }

  const error = new Error(response.statusText ?
    response.statusText : 'Failed[STATUS=' + response.status + '] : ' + response.url);

  error.response = response;
  throw error;
}

function delay(t, v) {
  return new Promise(((resolve) => {
    setTimeout(resolve.bind(null, v), t);
  }));
}

export async function fetchWithAccessToken(originalRequest, isRaw = true, retry = false, forceRefreshToken = false,
  beforeLogin = false) {
  let oAuth2Token = getOAuth2Token();
  let tokenExpired = isOAuth2TokenExpired(oAuth2Token);

  // REFRESH TOKEN AND INVOKE ORIGINAL FETCH
  if (((tokenExpired && oAuth2Token) || forceRefreshToken) && isRefreshTokenValid && !beforeLogin) {
    sem.take(() => {
      refreshTokenInProgress = true;

      oAuth2Token = getOAuth2Token();
      tokenExpired = isOAuth2TokenExpired(oAuth2Token);

      // provious operation may renewed refresh token so check işt againg, if it is ok skip refresh process
      if ((!tokenExpired && oAuth2Token) && !forceRefreshToken) {
        sem.leave();
        refreshTokenInProgress = false;
        return fetchWithAccessToken(originalRequest, isRaw, retry);
      }

      // token is not present . abort refresh process
      if (!oAuth2Token) {
        refreshTokenInProgress = false;
        sem.leave();
        return Promise.resolve({ data: [] });
      }
      console.log('Trying to refresh old token');

      return refreshToken(oAuth2Token)
        .then(originalRequest)
        .then(checkStatus)
        .then(response => {
          refreshTokenInProgress = false;
          sem.leave();
          return isRaw ? response : parseJSON(response);
        }
        )
        .catch(error => {
          refreshTokenInProgress = false;
          // previous refresh token process marked refresh token as invalid, skip error and go to login
          if (!isRefreshTokenValid) {
            console.log('Refresh token is invalid. Logout in progress');
            return Promise.resolve({ data: [] });
          }
          console.log(error);
          if ((error.response &&
            error.response.status >= 400 &&
            error.response.status < 404) || forceRefreshToken) {
            console.log('Go to login with errror:' + error + 'error.sessionExpired');

            isRefreshTokenValid = false;
            setTimeout(() => {
              sem.leave();
            }, 10000);
            return Promise.resolve({ data: [] });
          }
          sem.leave();
          throw error;
        });
    });
  }

  /** auth server hata return ediyorsa deadlock oluyor ve surekli kendini cagiriyor */
  if (refreshTokenInProgress) {
    console.log('oauth2.fetchWithAccessToken.refreshTokenInProgress');
    return delay(3000).then(() => {
      return fetchWithAccessToken(originalRequest, isRaw, retry);
    });
  }

  if (!isRefreshTokenValid) {
    return Promise.resolve({ data: [] });
  }

  return originalRequest()
    .then(checkStatus)
    .then((response) => {
      return checkStatusAndAccess(response, originalRequest, isRaw);
    })
    .then(response => (isRaw ? response : parseJSON(response)));
}

