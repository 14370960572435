const styles = theme => ({
  root: {
    width: '100%'
  },
  input: {
    fontSize: '12px',
    width: '480px',
    height: '56px',
    borderRadius: '64px',
    border: 'solid 1px #cecece',
    backgroundColor: '#ffffff',
    padding: '20px',
    fontFamily: 'Gilroy',
    boxSizing: 'inherit'
  },
  helperText: {
    fontSize: '10px',
    marginLeft: '2%'
  }
});

export default styles;

