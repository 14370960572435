import React from 'react';

class Copyrights extends React.Component {
  render() {
    return (

      <div id="copyrights" style={{backgroundColor: '#F4F5F6'}}>
        <div className="container clearfix">
          <div className="row">

            <div className="col bottommargin-sm widget_links">
              <a href="#" target="_blank"><img src= {require('../../images/logo-semosis.svg')} ></img></a>
            </div>

            <div className="col bottommargin-sm widget_links">
              <a href="#" target="_blank"><img src= {require('../../images/logo-nakitbasit.svg')}></img></a>
            </div>

            <div className="col bottommargin-sm widget_links">
              <a href="#" target="_blank"><img src= {require('../../images/logo-kimlikbasit.svg')} ></img></a>
            </div>

            <div className="col  widget_links">
              <a href="https://alneo.com.tr/" target="_blank"><img src= {require('../../images/logo-alneo.svg')}></img></a>
            </div>
            
            <div className="col  widget_links">
              <a href="https://www.getinsha.com/tr"  target="_blank"><img src= {require('../../images/getinsha.png')}></img></a>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Copyrights;
