import React, {Component} from 'react';
import PropTypes from 'prop-types';

import SwaggerUi, {presets} from 'swagger-ui';
// import 'swagger-ui/dist/swagger-ui.css';

import * as CustomBasePlugin from './Plugins/CustomBasePlugin';
import DisableTryItOutPlugin from './Plugins/DisableTryItOutPlugin';
import MyWrapComponentPlugin from './ComponentWrappers/OperationTagWrapper';

import 'swagger-ui-themes/themes/3.x/theme-material.css';
import './style.css';
import {DOCS_BASE_URL} from '../../../../config/paths';

class SwaggerUI extends Component {

  createSwaggerContainer() {
    SwaggerUi({
      dom_id: '#swaggerContainer',
      url: DOCS_BASE_URL + this.props.url, // 'http://petstore.swagger.io/v2/swagger.json',
      // deepLinking: true,
      // displayOperationId: true,
      docExpansion: 'full',
      defaultModelsExpandDepth: -1,
      // // spec: this.props.spec,
      presets: [presets.apis],
      plugins: [
        CustomBasePlugin.CustomBaseLayoutPlugin, DisableTryItOutPlugin, MyWrapComponentPlugin
      ],
      layout: 'CustomBaseLayout'
    });
  }

  componentDidMount() {
    this.createSwaggerContainer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.createSwaggerContainer();
  }

  render() {
    return (
      <div id="swaggerContainer" />
    );
  }
}

SwaggerUI.propTypes = {
  url: PropTypes.string
  // spec: PropTypes.object
};

SwaggerUI.defaultProps = {
  // url: `http://petstore.swagger.io/v2/swagger.json`
  // url:'http://10.76.166.12:8094/v2/api-docs?group=chinformation-getbranches-api'
};

export default SwaggerUI;
