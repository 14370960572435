const styles = theme => ({
  intro: {
    paddingTop: '15vh',
    paddingLeft: '15vw',
    marginBottom: '3vh'
  },
  typography: {
    width: '33vw',
    color: '#ffffff',
    fontSize: '7vh',
    fontStyle: 'normal',
    maxHeight: '-webkit-fill-available',
    fontFamily: 'Gilroy',
    fontWeight: 900,
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-2px'
  },
  txtRegister: {
    width: '149px',
    height: '14px',
    fontSize: '14px !important',
    fontFamily: 'Gilroy',
    fontWeight: 'bolder',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#4a90e2'
  },
  lblRegister: {
    fontSize: '14px'
  },
  btnRegister: {
    width: '70vw',
    maxWidth: '240px',
    height: '8vh',
    maxHeight: '56px',
    borderRadius: '32px',
    backgroundColor: '#ffffff',
    fontSize: '14px !important',
    marginBottom: '17%',
    marginLeft: '15vw',
    zIndex: 9999

  },
  section4: {
    webkitTransform: 'translate(0, -50%)',
    transform: 'translate(0, -50%)',
    color: '#fff'
  },
  span: {
    position: 'absolute',
    top: '0',
    left: '50%',
    width: '46px',
    height: '46px',
    marginLeft: '-23px',
    borderRadius: '100%',
    boxSizing: 'border-box',
    display: 'inline-block'
  },
  scroll: {
    alignSelf: 'center',
    paddingTop: '5%'
  },
  scrollIcon: {
    fontSize: '100px'
  },
  footerImageText: {
    backgroundImage: 'linear-gradient(to right, #3023ae 30%, #53a0fd 44%, #b4ec51 75%)',
    fontFamily: 'Gilroy',
    fontSize: '66px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '0.91',
    textAlign: 'center',
    '-webkitBackgroundClip': 'text',
    '-webkitTextFillColor': 'transparent'
  },
  countryList: {
    fontFamily: 'Gilroy',
    fontSize: '27px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1px',
    wordSpacing: '20px',
    textAlign: 'center',
    color: '#332ab3'
  }
});

export default styles;
