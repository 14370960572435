import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import { Route, Redirect } from 'react-router';
import createHistory from 'history/createBrowserHistory';
import Home from './components/Home';
import Header from './components/Header';
import Faq from './components/Faq';
import Contact from './components/Contact';
import Login from './components/Login';
import Register from './components/Register';
import NotFound from './components/NotFound';
import Confirm from './components/Confirm';
import Reminder from './components/Reminder';
import ChangePassword from './components/ChangePassword';
import Docs from './components/Docs';
import Products from './components/Products';
import MyApplications from './components/MyApplications';
import Footer from './components/Footer';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import NewApplication from './components/NewApplication';
import NotificationBar from './components/NotificationBar';
import AnnouncementBar from './components/AnnouncementBar';
import Announcement from './components/Announcement';
import Profile from './components/Profile';
import ReActivation from './components/ReActivation';
import PrivacyPolicy from './components/PrivacyPolicy'
import './App.css';
import { store } from './index';
import { initialize } from './redux/actions';
import { SnackbarProvider } from 'notistack';

export const history = createHistory();

class App extends Component {
  componentDidMount() {
    store.dispatch(initialize());
  }
  render() {
    return (
      <Router history={history}>
        <div>
          {/*
            <Header/>
          } */}
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/faq" component={Faq} />
            <Route path="/contact" component={Contact} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/confirm/:key" component={Confirm} />
            <Route path="/changepassword/:key" component={ChangePassword} />
            <Route path="/reminder/" component={Reminder} />
            <Route path="/docs" component={Docs} />
            <Route path="/products" component={Products} />
            <Route path="/reactivation" component={ReActivation} />
            <Route path="/announcement" component={Announcement} />
            <Route path="/privacyPolicy" component={PrivacyPolicy} />
            <AuthenticatedRoute path="/myapplications" component={MyApplications} />
            <AuthenticatedRoute path="/newapplication" component={NewApplication} />
            <AuthenticatedRoute path="/profile" component={Profile} />
            <Redirect from='/home' to='/' />
            <Route component={NotFound} />
          </Switch>

          <Footer/>
          <NotificationBar/>

          <SnackbarProvider maxSnack={3}>
            <AnnouncementBar/>
          </SnackbarProvider>

        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action)
});

export default connect(null, mapDispatchToProps)(App);
