import React from 'react';
import MUTextValidator from '../../../components/ui/MUTextValidator';
import ApplicationIcon from '../ApplicationIcon';

class ApplicationInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editPage: false,
      id: '',
      name: '',
      description: '',
      redirectUri: '',
      publickey: '',
      readOnly: false
    };
  }

  componentWillMount() {
    if (this.props.location.state !== undefined && this.props.editapplication) {
      // state passed from history.push
      const { appId, applications, readOnly } = this.props.location.state;

      Array.prototype.forEach.call(applications, value => {
        console.log('product id : ' + value.id);
        if (value.id === appId) {
          this.setState({
            editPage: true,
            id: value.id,
            name: value.name,
            description: value.description,
            redirectUri: value.oauthRedirectUri,
            publickey: value.publicKey,
            readOnly: readOnly
          });
          this.props.onApplicationInfoLoad(value.id, value.name, value.description, value.oauthRedirectUri, value.publicKey);
        }
      });
    }
    console.log(this.state.name + ' ' + this.state.description + ' ' + this.state.redirectUri + ' ' + this.state.publickey);

  }

    handleChange = event => {
      const fieldName = event.target.name;
      const fieldValue = event.target.value;

      this.setState({ [fieldName]: fieldValue });
      this.props.handleChange(fieldName, fieldValue);
    };

    render() {
      const { classes } = this.props;
      const { editPage, name, description, redirectUri, publickey, readOnly} = this.state;

      return (
        <div>
          <div id= 'loadImage' style={{ display: 'flex'}}>
            <ApplicationIcon {...this.props} />
          </div>
          <div id='appInfo' style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            <div className = {classes.textValidator}>
              < MUTextValidator
                id="name"
                label= {editPage ? 'Name*' : null }
                placeholder="Name*"
                onChange={this.handleChange}
                name="name"
                value={name}
                className = {classes.textField}
                validators={['required', 'maxStringLength:50', 'matchRegexp:^([\r\n\t\f\v \u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]|[a-zA-Z0-9_]|<br>)*?$']}
                errorMessages={['Required', 'Name must be less than 50 characters', 'Please change name.']}
                disabled = {readOnly}
              />
            </div>
            <div className = {classes.textValidator}>
              < MUTextValidator
                id="description"
                label= {editPage ? 'Description*' : null }
                placeholder="Description*"
                onChange={this.handleChange}
                name="description"
                value={description}
                className = {classes.textField}
                validators={['required', 'maxStringLength:255', 'matchRegexp:^([\r\n\t\f\v \u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]|[a-zA-Z0-9_]|<br>)*?$']}
                errorMessages={['Required', 'Description must be less than 255 characters', 'Please change your description.']}
                disabled = {readOnly}
              />
            </div>
            <div className = {classes.textValidator}>
              < MUTextValidator
                id="redirectUri"
                label= {editPage ? 'URI*' : null }
                placeholder="URI*"
                onChange={this.handleChange}
                name="redirectUri"
                value= {redirectUri}
                className = {classes.textField}
                validators={['required', 'maxStringLength:255', 'matchRegexp:^(http.*$|ftp.*$|https|localhost|www.*$)']}
                errorMessages={['Required', 'URI must be less than 255 characters', 'Invalid URI !']}
                disabled = {readOnly}
                />
            </div>
            <div className = {classes.textValidator}>
              < MUTextValidator
                id="publickey"
                label= {editPage ? 'Public Key*' : null }
                placeholder="Public Key*"
                onChange={this.handleChange}
                name="publickey"
                value={publickey}
                className = {classes.textField}
                validators={['required', 'maxStringLength:300', 'matchRegexp:^([\r\n\t\f\v \u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]|[a-zA-Z0-9_]|<br>)*?$']}
                errorMessages={['Required', 'Public key must be less than 300 characters', 'Please change public key.']}
                disabled = {readOnly}
              />
            </div>
          </div>

        </div>
      );
    }
}

export default (ApplicationInfo);
