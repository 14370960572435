const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    display: 'flex'
  },
  textField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '12px'
  },
  menu: {
    width: 200
  },
  registerButton: {
    marginLeft: theme.spacing.unit,
    marginTop: '20px',
    display: 'flex'
  },
  margin: {
    margin: theme.spacing.unit
  },
  bootstrapFormLabel: {
    marginTop: '-35px',
    marginLeft: '8px'
  },
  textFieldEmail: {
    marginLeft: '45px',
    marginRight: '-5px',
    clear: 'right'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  btnSubmit: {
    width: '100%',
    marginTop: '2%',
    marginBottom: '15%'
  },
  input: {
    height: '100px'
  }

});

export default styles;
