import React from 'react';
import MUTypography from '../../../../../ui/MUTypography';

const Info3 = (props) =>
  <div>
    <MUTypography variant='h4' className={props.classes.h4}>
    API Usage
    </MUTypography>
    <br/>
    <MUTypography variant='h5' className={props.classes.h5}>
    Authorization
    </MUTypography>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <MUTypography variant='display1' className={props.classes.paragraph}>
      You must have an access token in order to call FLYP APIs whether private or public (see Authorization section). Access token should set to Authorization header in any request.
      </MUTypography>
      <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
        •	Authorization: Bearer {'<'}Access_token{'>'}
      </MUTypography>
    </div>

    {/* <MUTypography variant='h5' className={props.classes.h5}>
    Signing Requests
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    In order to call private apis you must sign your request. You must have public private key pairs in PKCS#8 format.
    Public key should be saved on application creation stage.
    Fields must be sign with private key as shown below and signature should be placed in header with ‘sign’ key.
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Please define your keys without the "Begin" and "End" terms.
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    POST : token data and body
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    GET : token data and query string
    </MUTypography>
    <MUTypography variant='h6' className={props.classes.h6}>
    Sample Signing Method
    </MUTypography>

    <MUTypography variant='display1' className={props.classes.paragraph}>
      <code>
        {'import java.security.KeyFactory;\r\n'}<br/>
        {'import java.security.PrivateKey;\r\n'}<br/>
        {'import java.security.Signature;\r\n'}<br/>
        {'import java.security.spec.PKCS8EncodedKeySpec;\r\n'}<br/>
        {'import java.util.Base64;\r\n \r\n'}<br/><br/>
        &emsp; &emsp; &emsp; {'public class SignatureGenerationService {'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; {'\r\n \r\n \r\npublic static void main(String args[]) {'}<br/><br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp; {'\r\n                                String strSignature = \"\";'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;{'\r\n                                String strPrivateKey = \"Your Private Key\";'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;{'\r\n                                String message = \"Access Token\"+\r\n                                                                \"Request Body\";'}<br/><br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;{'\r\n \r\n                                try {\r\n     '}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\nKeyFactory keyFactory = KeyFactory.getInstance(\"RSA\");'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\nbyte[] keyBytes = strPrivateKey.getBytes();'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\n \r\nBase64.Decoder decoder = Base64.getMimeDecoder();'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\nbyte[] decodedBytes = decoder.decode(keyBytes);'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\n \r\nPKCS8EncodedKeySpec privateKeySpec = new PKCS8EncodedKeySpec(decodedBytes);'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\nPrivateKey privateKey = keyFactory.generatePrivate(privateKeySpec);'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\n \r\n\/\/ Get an instance of Signature object and initialize it.'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\nSignature signature = Signature.getInstance(\"SHA256withRSA\");'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\nsignature.initSign(privateKey);'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\n \r\nBase64.Encoder encoder = Base64.getEncoder();'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\n\/\/ Supply the data to be signed to the Signature object'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\n\/\/ using the update() method and generate the digital'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\n\/\/ signature.'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\nbyte[] bytes = message.getBytes();'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\nsignature.update(bytes);'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\n\r\nbyte[] digitalSignature = signature.sign();'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;&emsp;{'\r\n \r\nstrSignature = encoder.encodeToString(digitalSignature);'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;{'\r\n \r\n                                } catch (Exception e) {'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;{'\r\n                                                e.printStackTrace();'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;{'\r\n                                }'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;{'\r\n                                System.out.println(\"Sign : \"+ strSignature);'}<br/>
        &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp;&emsp;{'\r\n                }\r\n\r\n' }

   } </code> 

    </MUTypography>*/}
    <br/>
    <MUTypography variant='h5' className={props.classes.h5}>
    IP Filter
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    IP Filter is applied to all api calls. Filtering operation checks if the request is made from IP which is specified while creating application. The request made from unknown ip is responded with "Invalid IP" error message.
    </MUTypography>
    <br/>

    <MUTypography variant='h5' className={props.classes.h5}>
    Rate Limiter
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    In api calls there are limitations to 5 request per seconds and 100 request per day for a client. In case of over use 'Too many requests' error message is thrown.
    </MUTypography>
    <br/>

    <MUTypography variant='h5' paragraph={true} className={props.classes.h5}>
    OTP Free Calls
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      "{<a href='https://apitest.flyp.com.tr/api' style = {{fontWeight: 'bold'}}>https://apitest.flyp.com.tr/api</a>}" is the base url for api calls.
    Documented service endpoints, each product has a specific endpoint, should be added to base url while making a request.
    You’ll get response on Otp free apis.
    Guidance for otp required apis as detailed below.
    </MUTypography>

    <MUTypography variant='h5' paragraph={true} className={props.classes.h5}>
    OTP Required Calls
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Some of FLYP APIs require otp(one time password) also known as sms login to complete call(see section Api Products for details).
    When you call an api, you'll get a "transaction_id" as response.
    </MUTypography>

    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    At the same time, user will be provided an otp message via sms.
      <ul>
        <li>status: Status of process</li>
        <li>transaction_id: The ID will be used to complete process</li>
      </ul>
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    In order to complete api call process, transaction_id and otp credentials must be send in a second POST request. This endpoint is fixed for all otp required FLYP APIs.
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    POST https://apitest.flyp.com.tr/api/execute/
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    body parameters:
      <p>
        <ui>
          <li>transaction_id: The id provided you in api call</li>
          <li>otp_info: One time password send to user via sms ( Any 6-digits number usable for tests )</li>
        </ui>
      </p>
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    headers:
      <p>
        <ui>
          <li>Authorization: Bearer {'<'}Access_token{'>'}</li>
        </ui>
      </p>
    </MUTypography>

    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    On a successful execute call, execution of api will be completed and api specific response will be returned. (also see responses : Response Object, How it works/Api )
    </MUTypography>

    <MUTypography variant='h5' className={props.classes.h5}>
    Response Object
    </MUTypography>

    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    FLYP APIs has a standard JSON response object as shown below.<br></br>
      <code> {'\{ "data"\: \{\},"header"\: \{"message"\: ,"status"\: "statusCode"\: ,"detailCode"\: ,"warnings": [],\} \}'}</code>
      <br/>
      <ul>
        <li>data: The response of called api. See Api Products section for api specific response</li>
        <li>header: Contains generic info about api call</li>
        <li>message: Error message. On successful case "null"</li>
        <li>status: Status of api call. “SUCCESS” or “ERROR”</li>
        <li>statusCode: Http status code</li>
      </ul>
      <br/>
    </MUTypography>
  </div>;

export default Info3;

