import React from 'react';
import _ from 'lodash';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { MUValidatorForm } from 'basic-components';
import Button from '../ui/Button';
import MUTypography from '../ui/MUTypography';
import { bindActionCreators } from 'redux';
import * as apiDashboardService from '../../services/apidashboardservice';
import * as Actions from '../../redux/actions';
import ApplicationInfo from './ApplicationInfo';
import TokenInfo from './TokenInfo';
import APIsInfo from './APIsInfo';
import IpList from './IpList';
import {getProducts as getProductsSelect} from '../../redux/selectors';
import MUCircularProgress from '../ui/MUCircularProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

class NewApplication extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editPage: false,
      readOnly: false,
      id: '',
      name: '',
      description: '',
      icon: '',
      selectedProducts: [],
      redirectUri: '',
      publickey: '',
      clientId: '',
      clientSecret: '',
      checked: [],
      products: [],
      selectedFile: null,
      file: '',
      ipList: [],
      onServiceCall: true,
      loading: false
    };
  }

  addIpToList = (ip) => {
    this.setState({
      ipList: [...this.state.ipList, ip]
    });
  };

  deleteIpFromList = (ip) => {
    let temp = [...this.state.ipList];

    let index = temp.indexOf(ip);

    temp.splice(index, 1);
    this.setState({ipList: temp});
  };

  componentWillMount() {
    const productList = this.props.selectProducts;

    if (this.props.location.state !== undefined && this.props.editapplication) {
      // state passed from history.push
      const { appId, applications, readOnly } = this.props.location.state;

      Array.prototype.forEach.call(applications, value => {

        console.log('product id : ' + value.id);
        if (value.id === appId) {
          this.setState({
            editPage: true,
            id: value.id,
            readOnly: readOnly,
            name: value.name,
            description: value.description,
            redirectUri: value.oauthRedirectUri,
            publickey: value.publicKey,
            clientId: value.oauthClientId,
            clientSecret: value.oauthClientSecret,
            checked: value.productList,
            icon: value.icon,
            ipList: value.ipList
          });
        }
      });
    }

    if (!_.isEmpty(productList)) {this.setState({ products: productList, onServiceCall: false});} else {
      console.log('get products');
      this.getProducts();
      console.log('got products');
    }
  }

  getProducts() {
    apiDashboardService.getProducts()
      .then((responseJson) => {
        if (responseJson.data) {
          console.log('get products success');

          this.setState({ products: responseJson.data});
          this.props.actions.addProducts(responseJson.data);
        }
      })
      .catch((exception) => {
        console.log('get products error');

        // TODO: Show Generic Error to user - System error occured, please try again
        console.log('NewApplication - refreshProducts => ' + JSON.stringify(exception));
      })
      .finally(()=>{
        this.setState({ onServiceCall: false });
      });
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handleToggle = (checked) => {
    this.setState({ checked: checked });
  };

  handleSubmit = () => {

    console.log('submitted');
    this.setState({
      selectedProducts: [...this.state.checked],
      loading: true
    });

    console.log('before save application');

    this.saveApplication();

  }

  saveApplication = () => {
    const { id, name, description, icon, redirectUri, publickey, clientId, clientSecret, checked, ipList } = this.state;

    apiDashboardService.saveApplication(id, name, description, icon, checked,
      redirectUri, publickey, clientId, clientSecret, ipList)
      .then((responseJson) => {

        this.redirectToApplications();
        console.log(responseJson);
      }).catch((error) => {
        if (_.isNil(error.response)) {
          if (!_.isNil(error.message)) {
            this.props.actions.setNotification(error.message, 'error');
          } else {
            this.props.actions.setNotification('System error occured', 'error');
          }
          return;
        }

        error.response.json()
          .then(r => {
            console.log('ERROR_newapplication: ' + JSON.stringify(r.header));
            this.props.actions.setNotification(r.header.message, 'error');
          });
      })
      .finally(()=>{
        this.setState({loading: false});
      });
  }

  redirectToApplications() {
    this.props.history.push('/');
    this.props.history.push('/myapplications');
  }

  renewTokens = (clientId, clientSecret, editPage) => {
    this.setState({
      editPage: editPage,
      clientId: clientId,
      clientSecret: clientSecret
    });
    console.log('clientID: ' + this.state.clientId);
    console.log('clientSecret: ' + this.state.clientSecret);
  }

  fileChangedHandler = (iconBytes) => {
    this.setState({
      icon: iconBytes
    });
  }

  onApplicationInfoLoad = (id, name, description, redirectUri, publickey) =>{
    this.setState({
      id: id,
      name: name,
      description: description,
      redirectUri: redirectUri,
      publickey: publickey
    });
  }

  onIpListLoad = (ipList) => {
    this.setState({ipList: ipList});
  }

  onTokenInfoLoad = (clientId, clientSecret) =>{
    this.setState({clientId: clientId, clientSecret: clientSecret});
  }

  onAPIsInfoLoad = (productList) => {
    this.setState({ checked: productList});
  }

  onIconLoad = (icon) => {
    this.setState({icon: icon});
  }

  render() {
    const { readOnly, loading, onServiceCall } = this.state;
    const {classes, editapplication} = this.props;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        {this.props.open ?
          <div>
            {editapplication ?
              <MUTypography className= {classes.appType} variant='h4'>
  Edit Application
              </MUTypography> : <MUTypography className= {classes.appType} variant='h4'>
  New Application
              </MUTypography>}

            <br/>
            {
              onServiceCall ?
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                  <MUCircularProgress className={this.props.classes.progress} size={50}></MUCircularProgress>
                </div> :
                <div>
                  <MUValidatorForm
                    onSubmit={this.handleSubmit}
                  >
                    <div id= 'applicationInfo'>
                      <ApplicationInfo
                        {...this.props}
                        handleChange={this.handleChange}
                        fileChangedHandler={this.fileChangedHandler}
                        onApplicationInfoLoad={this.onApplicationInfoLoad}
                        onIconLoad={this.onIconLoad} />
                    </div>
                    <div id= 'ipList'>
                      <IpList {...this.props}
                        handleChange = {this.handleChange}
                        addIpToList= {this.addIpToList}
                        deleteIpFromList = {this.deleteIpFromList}
                        onIpListLoad = {this.onIpListLoad}/>
                    </div>
                    {editapplication ?
                      <TokenInfo {...this.props} renewTokens = {this.renewTokens} onTokenInfoLoad={this.onTokenInfoLoad} /> : null}

                    <div >
                      <APIsInfo {...this.props} handleToggle={this.handleToggle} onAPIsInfoLoad = {this.onAPIsInfoLoad}/>
                    </div>

                    <div>
                      {readOnly ? null :
                        <Button
                          classes={{ root: classes.btnRefreshToken, text: classes.txtRefreshToken, label: classes.label }}
                          disabled={loading}
                          text = {editapplication ? 'Save' : 'Add' }
                          type = "submit"
                          style = {{width: '100%'}}
                        >
                        </Button>
                      }
                      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>

                  </MUValidatorForm>
                </div>
            }
          </div> :
          null}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
  products: state.products,
  selectProducts: getProductsSelect(state)
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles))(NewApplication);

