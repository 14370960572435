import React from 'react';
// import {MUTypography} from 'basic-components';
import MUTypography from '../../../../../ui/MUTypography';
const Info1 = (props) =>
  <div>
    <MUTypography variant='h4' className={props.classes.h4}>
Getting Started
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.h6}>
Welcome to the FLYP API Developer Portal
    </MUTypography>
    <MUTypography className={props.classes.paragraph} variant='display1'paragraph={true}>
Developer Portal lets you to create application using FLYP APIs. We supplied everything you need to get your first application start.
We recommend you to go through the guide below including Authorization and API Usage sections.
    </MUTypography>

    <MUTypography variant='h6' className={props.classes.h6}>
How to create developer account ?
    </MUTypography>
    <MUTypography variant='display1' gutterBottom className={props.classes.paragraph}>
Developer account is free and easy. Click "Register" from the home page, then enter your name, email address, phone number and password. We’ll send you an email with an activation link.
You'll be ready for developing after click activation link.
    </MUTypography>

    <MUTypography variant='h6' className={props.classes.h6}>
How to create an app ?
    </MUTypography>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <MUTypography variant='display1' gutterBottom className={props.classes.paragraph}>
        Once you created and activated your account for portal, you are so close to create applications and use API.
      </MUTypography>
      <MUTypography variant='display1' gutterBottom className={props.classes.paragraph}>
        1- Click to Applications, then New Application button
      </MUTypography>
      <MUTypography variant='display1' gutterBottom className={props.classes.paragraph}>
        2- Fill out the Name, Description, Redirect URI and Public Key fields that are required.
      </MUTypography>
      <MUTypography variant='display1' gutterBottom className={props.classes.paragraph}>
        3- IP field, let us to prevent request made from other than your servers, is optional in developer environment but required in live environment
      </MUTypography>
      <MUTypography variant='display1' gutterBottom className={props.classes.paragraph}>
        4- Activate apis required for your application. its available to switch apis in time.
      </MUTypography>
      <MUTypography variant='display1' gutterBottom className={props.classes.paragraph}>
        5- Click to Add button
      </MUTypography>
    </div>
  </div>;

export default Info1;
